import React, { useState, useEffect, useRef } from "react"
import AireCalculator from "../../tools/aire-calculator/index"
import * as styles from "../../styles/tools.module.css"
import Footer from "../../components/footer"
import { scrollTo } from "../../utils/index"
import SEO from "../../components/seo"
import PopUpModuleIndia from "../../tools/aire-calculator/modules/PopUpModuleIndia"
import { Helmet } from "react-helmet"

function Index() {
  const [Aire, setAire] = useState(false)
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(window?.location.href)
  }, [])

  const startAire = () => {
    setAire(true)
  }

  const myRef = useRef(null)
  const aireRef = useRef(null)

  const seo = (source = "default") => {
    return (
      <>
        {source == "default" ? (
          <SEO
            title={
              "AIRᵉ Calculator- A Self Audit Tool to assess your Employee Recognition Program."
            }
            description={
              "Get an overall analysis of your company's Recognition Program to make it more effective."
            }
          />
        ) : (
          <SEO
            title={
              "AIRᵉ Calculator- A Self Audit Tool to assess your Employee Recognition Program."
            }
            description={
              "Get an overall analysis of your company's Recognition Program to make it more effective."
            }
            meta={[
              {
                name: `robots`,
                content: `noindex`,
              },
            ]}
          />
        )}
      </>
    )
  }

  const renderLandingPage = () => {
    return (
      <>
        {seo("shrm")}
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            defer
            src="//js.hs-scripts.com/6153052.js"
          ></script>
        </Helmet>
        <div ref={aireRef}>
          <header className="fixed bg-white w-screen top-0 shadow-darkgray">
            <div className="md:container mr-auto">
              <a href="https://www.vantagecircle.com/">
                <img
                  className="ml-2 md:ml-24 h-12 md:h-20"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1671606684/prod/companylogos/positive/355_vantage_circle_logo.png"
                  alt="Vantage Circle Logo"
                />
              </a>
            </div>
          </header>
          <section>
            <div
              className={`${styles.backgroundCoverImgHrms} md:p-20 p-2 text-center w-100`}
            >
              <div className="md:container md:flex justify-between pt-8 pb-8 md:pb-0 gap-10">
                <div className="md:pt-10 pb-8 md:pb-5 pt-8 md:w-1/2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2022/02/AIRe_Logo_-1.png"
                    alt="AIRe Logo"
                    className="md:h-28 h-16 md:mx-0 mx-auto"
                  />
                  <div
                    className={`text-3xl md:text-6xl text-white font-semibold md:mt-4 md:text-left text-center md:leading-10 + ${styles.headingShrm}`}
                  >
                    Evaluate Your&nbsp;
                    <span className="text-orange">
                      Employee Recognition
                    </span>{" "}
                    Program
                    <br />
                    With AIRᵉ Assessment!
                    <br />
                    <div
                      className="text-white text-lg font-normal mt-5"
                      style={{ lineHeight: "2rem" }}
                    >
                      Join 1200+ Companies Worldwide in Improving Employee
                      Recognition with AIRᵉ Framework!
                    </div>
                    <div
                      className="hidden md:block text-white text-lg font-normal mt-3 inline"
                      style={{ lineHeight: "2rem" }}
                    >
                      Also, download the latest{" "}
                      <a href="#benchmarkSec" className="underline text-white">
                        Benchmarking Report{" "}
                      </a>
                      Powered by <b>SHRM India</b>
                    </div>
                  </div>
                </div>
                <div className="md:pt-20 md:w-1/2">
                  <PopUpModuleIndia startAire={startAire} />
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col  md:flex-row lg:m-16 bg-red-300">
            <div className="md:p-2 p-4 md:text-2xl">
              <h4 ref={myRef} className="md:mt-0 md:my-12">
                The DIY audit provides an overall assessment of your company's
                existing recognition program
              </h4>
              <ul className="md:pt-1 py-4 md:py-0">
                <li className="md:my-12">
                  <span className="text-orange ">&#10004;</span> Test and
                  enhance your existing employee recognition program.
                </li>
                <li className="md:my-12">
                  <span className="text-orange ">&#10004;</span> Test and design
                  a new recognition program based on the AIR<sup>e</sup>{" "}
                  framework.{" "}
                </li>
              </ul>
              <h4 className="pt-4 md:my-16">
                Respond to the questions based on your current R&R strategy to
                receive a data-backed quality score that compares to global
                recognition benchmarks.
              </h4>
              <div className="flex justify-center lg:justify-start text-center lg:text-left w-full my-7">
                <div className="cursor-pointer md:w-4/6 xl:w-1/3 flex justify-center lg:justify-start whitespace-nowrap">
                  <a
                    className="vc-colored-btn-responsive min-w-fit max-w-fit"
                    // onClick={onClickPopupHandler}
                    onClick={() => scrollTo(aireRef)}
                    id="aire-cal-link"
                  >
                    Start your AIRᵉ Assessment
                  </a>
                </div>
              </div>
            </div>
            <img
              className={styles.emotionalImg}
              src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Emotional_connect.png"
            ></img>
          </section>
          <section className="w-full flex flex-col place-items-center">
            <div className="w-full lg:w-4/6 flex place-content-center">
              <h2 className="font-bold text-3xl md:text-4xl lg:text-6xl text-gray-500 my-7 text-center">
                Why&nbsp;
                <span className="text-purple-200">AIRᵉ Framework?</span>
              </h2>
            </div>
            <p className="text-center w-11/12 md:4/6 xl:w-1/2 mt-3 text-xl">
              The AIRᵉ Framework is a powerful tool that gives companies a
              baseline for creating a strong foundation for your recognition
              program. Let your workforce breathe upon and thrive the 'AIRᵉ'
              they need.
            </p>
            <div className="w-9/12 xl:w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-16">
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Adopt_a_holistic_structured_approach.png" />
                <p className="text-center my-4">
                  Adopt a holistic & structured approach
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Instill_a_Culture_of_Recognition.png" />
                <p className="text-center my-4">
                  Instill a Culture of Recognition
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Cater_to_Your_Hybrid_Workforce.png" />
                <p className="text-center my-4">
                  Cater to Your Hybrid Workforce
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Assess_With_Data-Backed_Analytics.png" />
                <p className="text-center my-4">
                  Assess With Data-Backed Analytics
                </p>
              </div>
            </div>
          </section>
          <section
            id="benchmarkSec"
            className="w-full w-screen md:flex justify-center md:px-32 mb-10"
          >
            <img
              style={{
                padding: "1rem",
                borderRadius: "30px",
                "@media (min-width: 768px)": {
                  width: "25rem",
                  borderRadius: "10px",
                },
              }}
              src="https://res.cloudinary.com/vantagecircle/image/upload/v1690544677/gatsbycms/uploads/2023/07/quantigy01.png"
            ></img>
            <div className="md:flex w-100 mx-15 justify-center md:pl-32 items-center">
              <div className="card-1 w-full mb-10 md:mb-0 md:mt-10 p-5 md:p-0">
                <h2 className="mb-5 sm:mb-10 text-3xl font-semibold text-gray-900 sm:text-4xl">
                  Download the latest R&R Benchmarking Report!
                </h2>
                <ul className="form-bullets list-inside orangebullets">
                  <li>
                    A quantitative report of R&R Programs from combined
                    knowledge of Vantage Circle and SHRM India.
                  </li>
                  <li>
                    Average AIR<sup>e</sup> score across Recognition programs in
                    India.
                  </li>
                  <li>
                    Industry and Sector wise insights and best practices of R&R
                    Programs.
                  </li>
                  <li>
                    9 Industry Leaders Speaking on best practices of R&R
                    Programs.
                  </li>
                  <li>
                    Insights from the R&R Survey Across more than 150+
                    organization.
                  </li>
                </ul>
                <div className="flex justify-center items-center md:justify-start md:items-center mt-5 sm:mt-8 md:mt-10 ">
                  <div className="flex justify-start">
                    <a
                      href="/aire-benchmarking-report-india/"
                      className="hs-button primary large"
                      style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                    >
                      Download now
                    </a>
                  </div>
                  <img
                    className="ml-5"
                    style={{
                      width: "5rem",
                      height: "2.5rem",
                      marginBottom: "2px",
                    }}
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1690869100/gatsbycms/uploads/2023/08/SHRM-logo1.png"
                  ></img>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <section className="w-full flex flex-col place-items-center bg-purple-200 p-5 lg:p-10 mt-12">
          <h2 className="text-center text-purple-100 font-bold text-3xl md:text-4xl container">
            "Want to know how the AIRᵉ Framework can be utilized to make your
            recognition programs more effective?"
          </h2>
          <button className="rounded w-4/6 md:w-2/6 lg:w-2/6 xl:w-1/6 mt-6 flex items-center justify-center  py-2 border border-transparent text-sm mb-3 md:text-base text-purple-300 bg-white md:text-lg transition duration-500 ease-in-out transform hover:shadow hover:-translate-y-1">
            <a
              href="https://www.vantagecircle.com/hr-academy/whitepapers/aire-framework/"
              rel="alternate"
              target="_blank"
            >
              Download Whitepaper
            </a>
          </button>
        </section> */}
        <Footer slantedTop={false} />
      </>
    )
  }

  const renderTool = () => {
    return (
      <>
        {seo("shrm")}
        <AireCalculator source="vc-aire-india" url={url} />
      </>
    )
  }
  return <>{Aire == true ? renderTool() : renderLandingPage()}</>
}

export default Index
